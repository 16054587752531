import { Grid } from '@mui/material';
import CloudparkPayment from '../assets/propark-logo.png';

export default function Footer({ isFixed }) {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="flex-start" className="footer-logo" style={isFixed ? { position: 'fixed' } : {}}>
      <img src={CloudparkPayment} alt="Cloudpark" width={65} />
    </Grid>
  );
}
