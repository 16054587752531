import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { datadogLogs } from '@datadog/browser-logs';
import App from './App';

if (process.env.NODE_ENV === 'test') {
  // eslint-disable-next-line
  const { worker } = require('./mocks/browser');
  worker.start();
}

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_LOG_TOKEN,
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  forwardConsoleLogs: ['error'],
  service: 'violations-parker',
  sampleRate: 100
});

const theme = createTheme({
  palette: {
    mode: 'light'
  },
  typography: {
    fontFamily: "'Noto Sans', sans-serif"
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          backgroundColor: '#000',
          '&:hover': {
            backgroundColor: '#000'
          }
        }
      }
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);
