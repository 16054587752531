import React, { Suspense, useEffect, Component, useState } from 'react';
import { CircularProgress, Backdrop, Typography, Grid } from '@mui/material';
import { WarningAmber } from '@mui/icons-material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { auth, signInAnonymously } from './services/firebase';
import Footer from './components/Footer';
import './styles/App.less';

const LandingPage = React.lazy(() => import('./components/LandingPage'));

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    initFirebase();
  }, []);

  async function initFirebase() {
    if (auth && signInAnonymously) {
      await signInAnonymously(auth);
    }
    setLoggedIn(true);
  }

  const fallbackSpinner = () => (
    <Backdrop open className="fallback-spinner">
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  return (
    <div className={`parker-app ${window.innerWidth > 500 ? 'desktop-mode' : ''}`}>
      <ErrorBoundary>
        <Router>
          <Suspense fallback={fallbackSpinner()}>
            <Routes>
              <Route element={<LandingPage loggedIn={loggedIn} />} path="/:code" />
              <Route element={<LandingPage loggedIn={loggedIn} />} path="*" />
            </Routes>
          </Suspense>
        </Router>
      </ErrorBoundary>
    </div>
  );
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { errorMessage: '' };
  }

  static getDerivedStateFromError(error) {
    return { errorMessage: error };
  }

  componentDidCatch(error) {
    console.error(error);
  }

  render() {
    if (this.state.errorMessage) {
      return (
        <>
          <div className="status">
            <WarningAmber />
            <Typography variant="subtitle1" component="div" className="title">
              Hmm. It looks like something went wrong.
            </Typography>
            <Typography variant="subtitle1" component="div" className="title">
              Please refresh the page.
            </Typography>
            <Typography variant="body2" component="div" className="message">
              If this keeps occuring please send an email to:
              <div>
                <a href="mailto:jake.conniff@propark.com" rel="noopener">
                  jake.conniff@propark.com
                </a>
              </div>
              <br />
              <br />
            </Typography>
          </div>
          <Grid container direction="row" justifyContent="center" alignItems="flex-start" className="details">
            <Footer isFixed />
          </Grid>
        </>
      );
    }
    return this.props.children;
  }
}

export default App;
